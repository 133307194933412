'use client';

import DashboardDynamicLoginButtons from "src/sections/auth/signup/components/dashboard-dynamic-login-buttons";
import PropTypes from "prop-types";
import {useOffSetTop} from "../../../hooks/use-off-set-top";
import {HEADER_DESKTOP_HEIGHT} from "../../../config";

// ----------------------------------------------------------------------


export default function HeaderAuth({showFull = false}) {
  // This is here to update html class
  useOffSetTop(HEADER_DESKTOP_HEIGHT);

  return (
    <DashboardDynamicLoginButtons showFull={showFull}/>
  );
}

HeaderAuth.propTypes = {
  showFull: PropTypes.bool,
};
