'use client';

import { createContext, useContext } from 'react';

// ----------------------------------------------------------------------

export const SignupContext = createContext({});

export const useSignupContext = () => {
  const context = useContext(SignupContext);

  if (!context) throw new Error('useSignupContext must be use inside SettingsProvider');

  return context;
};
