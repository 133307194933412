'use client';

// next
// @mui
// hooks
import {useResponsive} from 'src/hooks/use-responsive';
// components
import {IconButtonAnimate, Iconify} from 'src/components/landing';
import {useSettingsContext} from "./context";

// ----------------------------------------------------------------------

export default function ThemeSelector() {
  const settings = useSettingsContext();
  const isDesktop = useResponsive('up', 'sm');

  return (
    <IconButtonAnimate color='inherit' onClick={() => settings.onUpdate('themeMode', settings.themeMode === 'light' ? 'dark' : 'light')}
                       sx={{ml: isDesktop ? 0 : 1, mr: isDesktop ? 0 : -1}}>
      <Iconify icon={settings.themeMode === 'light' ? 'solar:moon-outline' : 'solar:sun-2-linear'} sx={{width: 20, height: 20}}/>
    </IconButtonAnimate>
  );
}
