/* eslint-disable react-hooks/exhaustive-deps */

'use client';

import {useSearchParams} from 'next/navigation';
// @mui
import Stack from '@mui/material/Stack';
// routes
// config
import {PATH_AFTER_LOGIN} from 'src/config-global';
// hooks
// auth
import {useAuthContext} from 'src/auth/hooks';
// components
import Iconify from 'src/components/iconify';
import Button from '@mui/material/Button';
import {RouterLink} from 'src/routes/components';
import {useSignupContext} from 'src/components/signup-popup/context/signup-context';
import {useSnackbar} from 'src/components/snackbar';
import PropTypes from 'prop-types';
import {useEffect} from 'react';
import {setCookie} from 'cookies-next';
import {Hidden} from "@mui/material";

// ----------------------------------------------------------------------

export default function DashboardDynamicLoginButtons({showFull = false}) {
  const {authenticated} = useAuthContext();
  const {logout} = useAuthContext();
  const {enqueueSnackbar} = useSnackbar();
  const searchParams = useSearchParams();
  const signup = useSignupContext();

  useEffect(() => {
    const by = searchParams.get('by');
    if (by) {
      setCookie('by_affiliate', by, {maxAge: 60 * 60 * 24 * 7}); // By Affiliate cookie stays for 7 Days
    }
  }, []);


  const handleLogout = () => {
    logout();
    enqueueSnackbar('Logged out.');
  };


  return (authenticated ? <Stack spacing={1} direction={showFull ? 'column' : 'row'}>
    {showFull ? <Button
      color='inherit'
      onClick={handleLogout}
    >
      Logout
    </Button> : <Hidden implementation="css" smDown>
      <Button
        color='inherit'
        onClick={handleLogout}
      >
        Logout
      </Button>
    </Hidden>}
    <Button component={RouterLink} href={PATH_AFTER_LOGIN} variant='contained' color='accent'
            sx={{height: 40, ml: 0}}>
      {showFull ? 'To Dashboard' : <><Hidden implementation="css" smDown>To Dashboard</Hidden><Hidden implementation="css" smUp>Dashboard</Hidden></>}
    </Button>
  </Stack> : <Stack spacing={1} direction={showFull ? 'column' : 'row'} sx={{whiteSpace: 'nowrap'}}>
    <Button
      color='inherit'
      onClick={() => signup.onToggle('login')}
    >
      {showFull ? 'Log in' : <><Hidden implementation="css" smDown>Log in</Hidden><Hidden implementation="css" smUp>Login</Hidden></>}
    </Button>
    <Button
      color='accent'
      variant='contained'
      endIcon={<Hidden implementation="css" smDown><Iconify icon='solar:double-alt-arrow-right-bold' sx={{ml: -0.5}}/></Hidden>}
      onClick={() => signup.onToggle('register', '/start')}
      sx={{height: 40, lineHeight: 1.2}}
    >
      {showFull ? 'Start for free' : <><Hidden implementation="css" smDown>Start for free</Hidden><Hidden implementation="css"
                                                                                                           smUp>Start</Hidden></>}
    </Button>
  </Stack>);
}

DashboardDynamicLoginButtons.propTypes = {
  showFull: PropTypes.bool,
};
