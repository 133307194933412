'use client';

import PropTypes from 'prop-types';
import {forwardRef} from 'react';
// next
import NextLink from 'next/link';
// @mui
import {styled, useTheme} from '@mui/material/styles';
import {Box, Grid, List, Link, Stack, ListItem, ListSubheader} from '@mui/material';
// config
import {HEADER_DESKTOP_HEIGHT} from 'src/config';
//
import {Image} from 'src/components/landing';
import {DialogAnimate, varFade} from 'src/components/landing/animate';
import {usePathname} from 'next/navigation';

// ----------------------------------------------------------------------

const SubLinkStyle = styled(ListItem, {
  shouldForwardProp: (prop) => prop !== 'active',
})(({active, theme}) => ({
  ...theme.typography.body2,
  fontWeight: 400,
  padding: 0,
  width: 'auto',
  cursor: 'pointer',
  whiteSpace: 'nowrap',
  marginRight: 16,
  color: theme.palette.text.secondary,
  transition: theme.transitions.create('color'),
  '&:hover': {
    color: theme.palette.text.primary,
  },
  ...(active && {
    ...theme.typography.subtitle3,
    color: theme.palette.text.primary,
  }),
}));

const ListSubheaderStyled = styled((props) => (
  <ListSubheader disableSticky disableGutters {...props} />
))(({theme}) => ({
  ...theme.typography.h5,
  marginBottom: theme.spacing(1.5),
  color: theme.palette.text.primary,
}));

// ----------------------------------------------------------------------

const NavDesktopMenu = forwardRef(({lists, isOpen, onClose, isScrolling, navItemRef}, ref) => {
  const pathname = usePathname();

  const theme = useTheme();

  const navItemOffsetLeft = navItemRef?.current?.offsetLeft
  const navItemOffsetTop = navItemRef?.current?.offsetTop

  return (
    <DialogAnimate
      hideBackdrop
      disableScrollLock
      open={isOpen}
      onClose={onClose}
      ref={ref}
      variants={
        varFade({
          distance: 80,
          durationIn: 0.16,
          durationOut: 0.24,
          easeIn: 'easeIn',
          easeOut: 'easeOut',
        }).inRight
      }
      PaperProps={{
        sx: {
          left: navItemOffsetLeft - 32,
          m: 0,
          maxWidth: 1,
          position: 'absolute',
          // borderRadius: '0 !important',
          top: navItemOffsetTop ? navItemOffsetTop + 40 : HEADER_DESKTOP_HEIGHT - 20,
          // Fix scroll on window
          '&::-webkit-scrollbar': {display: 'none'},
          msOverflowStyle: 'none',
          scrollbarWidth: 'none',
          pointerEvents: 'all'
        },
      }}
      onMouseLeave={onClose}
    >
      <Grid container columns={15} spacing={4}>
        <Grid item xs={12}>
          <Box sx={{position: 'relative', px: 2, py: 4}}>
            {lists?.map((list) => {
              const {subheader, items, cover, path} = list;

              return (
                <List key={subheader} disablePadding sx={{px: 2}}>
                  {path ? <NextLink key={subheader} href={path} passHref legacyBehavior>
                      <Link
                        color='inherit'
                        underline='hover'
                        variants={
                          varFade({
                            distance: 12,
                            durationIn: 0.16,
                            durationOut: 0.12,
                            easeIn: 'easeIn',
                          }).inRight
                        }
                        sx={{whiteSpace: 'nowrap'}}
                      >
                        <ListSubheaderStyled>{subheader}</ListSubheaderStyled>
                      </Link>
                    </NextLink>
                    : <ListSubheaderStyled>{subheader}</ListSubheaderStyled>
                  }

                  {cover && (
                    <NextLink href={path} passHref legacyBehavior>
                      <Box
                        variants={varFade({distance: 80}).inLeft}
                        sx={{display: 'block'}}
                      >
                        <Image
                          alt={cover}
                          src={cover}
                          sx={{
                            mb: 2.5,
                            minHeight: 80,
                            borderRadius: 1.5,
                            cursor: 'pointer',
                            transition: theme.transitions.create('opacity'),
                            border: `${theme.palette.divider}`,
                            '&:hover': {opacity: 0.8},
                          }}
                        />
                      </Box>
                    </NextLink>
                  )}

                  <Stack spacing={1.25} alignItems='flex-start' sx={{mt: 2}}>
                    {items?.map((item) => {
                      const {title, path: itemPath} = item;

                      const active = pathname === itemPath;

                      return <LinkItem key={title} title={title} href={itemPath} active={active}/>;
                    })}
                  </Stack>
                </List>
              );
            })}
          </Box>
        </Grid>
      </Grid>
    </DialogAnimate>
  );
});

NavDesktopMenu.propTypes = {
  isOpen: PropTypes.bool,
  isScrolling: PropTypes.bool,
  lists: PropTypes.array,
  onClose: PropTypes.func,
  navItemRef: PropTypes.object
};

export default NavDesktopMenu;

// ----------------------------------------------------------------------

LinkItem.propTypes = {
  active: PropTypes.bool,
  href: PropTypes.string,
  title: PropTypes.string,
};

function LinkItem({title, href, active}) {
  return (
    <NextLink key={title} href={href} passHref legacyBehavior>
      <Link
        color='inherit'
        underline='hover'
        variants={
          varFade({
            distance: 12,
            durationIn: 0.16,
            durationOut: 0.12,
            easeIn: 'easeIn',
          }).inRight
        }
      >
        <SubLinkStyle active={active}>
          {title}
        </SubLinkStyle>
      </Link>
    </NextLink>
  );
}
